<template>
    <div class="modal__backdrop" :class="{ modal__closed: !open, modal__open: open }" @click.self="$emit('closeModal')">
        <div class="modal__content">
            <button class="close-corner-x" type="button" @click.prevent="$emit('closeModal')">
                <span class="material-icons-outlined dark-text">close</span>
            </button>
            <div>
                <h2 class="margin-0 font-20">Add New Chart Note</h2>
                <hr class="purple fullwidth" />
                <div>
                    <FormClient :client_id="client_id" @getClient="getClient" v-if='client_id'/>
                </div>
                <hr class="purple fullwidth" />
            </div>
            <ValidationObserver ref="form">
                <form @submit.prevent="submit">
                    <div>
                        <h3>Chart Note</h3>
                        <span>Enter Chart Note</span>
                        <textarea v-model="chartNote.note" name="note" required :disabled="note_id && !editOn" />
                    </div>

                    <div class="align-right">
                        <button class="secondary cancel bottom-0" type="button" @click="$emit('closeModal')">
                            Cancel
                        </button>
                        <button class="primary bottom-0" type="submit" v-show="!note_id || (note_id && editOn)" :disabled="isSaveButtonDisabled">
                            Save
                        </button>
                        <button class="primary bottom-0" type="button" v-if="note_id && !editOn" @click="editOn = true">
                            Edit
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
    import FormClient from '@/components/formpieces/FormClient.vue';
    import { ValidationObserver } from 'vee-validate';
    import { notes } from '@/util/apiRequests';

    export default {
        components: { FormClient, ValidationObserver },
        name: 'ChartNoteModal',
        props: {
            open: Boolean,
            client_id: null,
            note_id: null
        },
        data() {
            return {
                escListener: null,
                chartNote: { note: '' },
                editOn: false,
                isSaveButtonDisabled: false
            };
        },
        methods: {
            createEscListener(self) {
                return function(e) {
                    if (e.key === 'Escape' && self.open) {
                        self.$emit('closeModal');
                    }
                };
            },
            getClient(data) {
                try {
                    this.chartNote.client_id = data.id;
                } catch (error) {
                    //this.$toasted.error('Could not get client data.');
                }
            },
            async getChartNote() {
                if (this.note_id) {
                    let { data } = await this.$api.get(notes.getChartNote(this.note_id));
                    this.chartNote.note = data?.info[0].note ?? '';
                } else {
                    this.chartNote.note = '';
                }
            },
            async submit() {
                let data = this.chartNote;
                // let activity = {};
                data.note_type = 'chart';
                const form = this.$refs.form;
                const isValid = await form.validate();
                if (!isValid) {
                    //this.$toasted.error('Invalid fields');
                    return;
                }
                try {
                    this.isSaveButtonDisabled = true;
                    // eslint-disable-next-line no-unused-vars
                    let page = this.$store.getters['availablePages'].find(
                        (element) => element.component_name == 'ChartNote'
                    );
                    data.page_id = page.id;
                    let id = this.note_id ? this.note_id : '0';
                    await this.$api.put(notes.saveNote(id), data);

                    this.$toasted.success('Successfully added your chart note.');
                    this.chartNote.note = '';
                    this.editOn = false;
                    this.note_id = null;
                    this.$emit('closeModal');
                    //if(res.status)
                    this.isSaveButtonDisabled = false;
                } catch (error) {
                    this.$toasted.error('There was an error posting your note.');
                    //this.$toasted.error('Could not submit your data.');
                }
            }
        },
        created() {
            this.escListener = this.createEscListener(this);
            window.addEventListener('keydown', this.escListener);
        },
        mounted() {},
        beforeDestroy() {
            window.removeEventListener('keydown', this.escListener);
        },
        watch: {
            note_id(newVal) {
                this.getChartNote();
            }
        }
    };
</script>
