<template>
    <div class="modal__backdrop" :class="{ modal__closed: !open, modal__open: open }" @click.self="close">
        <div class="modal__content">
            <!-- Add styling to keep X icon sticky in top right corner if possible -->
            <div @click.self="close" class="material-icons-outlined clear modal-close-x">clear</div>
            <EmailEditor :modal_client_id="client_id" :modal_client_guardian_email="client_guardian_email" @close-modal="$emit('closeModal')" ref='email'/>
        </div>
    </div>
</template>

<script>
    import EmailEditor from '@/pages/email/EmailEditor.vue';
    export default {
        components: { EmailEditor },
        name: 'EmailModal',
        props: {
            client_id: {
                default: null
            },
            client_guardian_email: {
                type: String,
                required: false,
                default: null
            },
            open: Boolean
        },
        data() {
            return {
                escListener: null
            };
        },
        methods: {
            createEscListener(self) {
                return function(e) {
                    if (e.key === 'Escape' && self.open) {
                        self.close();
                    }
                };
            },
            close(){
                this.$refs.email?.resetForm();
                this.$emit('closeModal');
            }
        },
        mounted() {},
        beforeDestroy() {
            window.removeEventListener('keydown', this.escListener);
        }
    };
</script>

<style></style>
