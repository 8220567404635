import { API } from 'aws-amplify';
import { resetpin } from '../../graphql/mutations'

export const resetPin = async ( email) => {
    
    let response = await API.graphql({
        query: resetpin,
        variables: { email:email }
    })
  

return response;
}