<template>
    
    <div class="modal__backdrop" :class="{ modal__closed: !open, modal__open: open }" @click.self="$emit('closeModal'); changeFormStatus(true)" >
        <div class="modal__content">
            
            <div class="d-flex justify-content-between">
                <h2 class="margin-0 font-20">Set Reminder</h2>
                <ejs-tooltip
                        class="tooltipcontainer"
                        content="Close"
                        target="#target"
                        cssClass="customtooltip"
                    >
                <span id="target" class="material-icons-outlined dark-text cursor-pointer close-corner-x" @click.prevent="$emit('closeModal'); changeFormStatus(true)">close</span>
                </ejs-tooltip>
            </div>
            <div class="d-grid">
                <div class="d-grid my-2">
                    <label for="">Reminder *</label>
                    <textarea   required placeholder="Write Reminder" name="" id="" cols="10" rows="3" class="input-text" style="border-radius: 8px !important" v-model="title"></textarea>
                </div>
                <div class="d-grid my-2">
                    <label for="">Date *</label>
                    <b-form-datepicker id="example-datepicker" v-model="reminderDate" class="mb-2"></b-form-datepicker>
                    <!-- <input type="date" name="" id="" class="input-text" style="border-radius: 8px !important" placeholder="Date" v-model="reminderDate"> -->
                </div>
                <div class="d-grid my-2">
                    <label for="">Time *</label>
                    <!-- <input type="time" name="" id="" class="input-text" style="border-radius: 8px !important" placeholder="Time" v-model="reminderTime"> -->
                    <b-form-timepicker id="ex-disabled-readonly" v-model="reminderTime"></b-form-timepicker>
                </div>
                
                <div class="d-grid my-2">
                    <h3 v-if="formValid==false" class="mb-2 " style="color: #A82A3D;">Please fill out all required fields.</h3>
                    <RecurrenceEditor
                        v-show="recurringReminder"
                        :startDate="reminderDate"
                        ref="recurrenceEditor"
                        @rRule="changeRuleData"
                        :module="'Reminder'"
                    />
                    <div class="d-flex">
                        <Toggle
                            id='isMonorToggle'
                            class='toggle-switch ms-0'
                            v-model='recurringReminder'
                            labelClass="switch"
                            spanClass="slider"
                        />
                        <span class="fs-6 fw-bolder">Recurring Reminder</span>
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <button class="btn btn-danger" @click.prevent="$emit('closeModal')">Cancel</button>
                    <button class="btn btn-primary" @click="createReminder">Save</button>
                </div>
            </div>
        </div>
        <Loading v-if="showLoader" />
    </div>
</template>

<script>
    import FormClient from '@/components/formpieces/FormClient.vue';
    import { ValidationObserver } from 'vee-validate';
    import RecurrenceEditor from '@/components/calendar/RecurrenceEditor';
    import { activityReminder, clients } from '@/util/apiRequests';
    import dayjs from 'dayjs';
    import { contactDetails } from '@/util/helpers/graphql/common.graphql.js';
    import { sendActivityReminderNotification } from '@/util/helpers/graphql/activityReminders.graphql.js';
    import Loading from '@/components/general/loading/loading.vue';
    export default {
        components: { FormClient, ValidationObserver, RecurrenceEditor,Loading },
        name: 'SetActivityReminderModal',
        props: {
            open: Boolean,
            client_id: null,
            note_id: null
        },
        data() {
            return {
                escListener: null,
                title: null,
                reminderDate: null,
                reminderTime: null,
                recurringReminder: false,
                rRulePayload: {},
                clientData: null,
                clientContactDetails: null,
                formValid:true,
                showLoader:false
            };
        },
        methods: {
            createEscListener(self) {
                return function(e) {
                    if (e.key === 'Escape' && self.open) {
                        self.$emit('closeModal');
                    }
                };
            },
            changeFormStatus(val){
                this.formValid=val;

            },
            changeRuleData(e) {
                this.rRulePayload = {};
                Object.keys(e).forEach((key) => {
                    this.rRulePayload[key] = e[key];
                });

                this.rRulePayload['dtstart'] = this.reminderDate;
            },
            
            async createReminder() {

                if(this.title==null || this.reminderDate==null || this.reminderTime==null)
                {
                    this.formValid=false;
                    return;
                }
                else
                {
                    this.formValid=true;
                }

                this.showLoader=true;
                const data = {
                    clientId: this.client_id,
                    title: this.title,
                    reminderDate: this.reminderDate,
                    reminderTime: this.reminderTime,
                }

                if( this.recurringReminder ) {
                    Object.assign( data, { recurrenceRule: this.rRulePayload } )
                }

                try {
                    const [ reminderResponse, notificationResponse ] = await Promise.all( [ this.$api.post( activityReminder.createReminder(), data ), this.sendActivityReminderNotification() ] );

                    const { success, message } = reminderResponse.data

                    if( success ) {
                        this.$toasted.success(message);
                    }
                    else {
                        throw new Error(message);
                    }

                    this.$emit('closeModal');
                    this.formValid=true;
                    this.title = null;
                    this.reminderDate = null;
                    this.reminderTime = null;
                    this.rRulePayload = null;

                }
                catch( err ) {
                    this.$toasted.error(err);
                }

                this.showLoader=false;

            },
            async sendActivityReminderNotification() {
                const response = await sendActivityReminderNotification( this.clientContactDetails.familyId, this.clientContactDetails.contactId );
                return true;
            },
            async getClient() {
                try {
                    const res = await this.$api.get(clients.getClient(this.client_id));
                    this.clientData = res.data?.[0];
                    return true;
                } catch (error) {
                    this.$cl(error);
                }
            },
            async getContactDetails() {
                try {
                    const email = this.clientData.email;
                    const seniorContactDetails = await contactDetails( email );
                    return {
                        familyId: seniorContactDetails.familyId,
                        contactId: seniorContactDetails.contactId
                    }
                }
                catch( e ) {
                    this.$toasted.error( e );
                }
            },
        },
        async created() {
            await this.getClient();
            this.clientContactDetails = await this.getContactDetails();
        },
        mounted() {},
        beforeDestroy() {
            window.removeEventListener('keydown', this.escListener);
        },
        watch: {
        }
    };
</script>
<style>
    .input-text {
        font-family: 'Roboto';
        color: #8f8181;
        font-size: 15px;
    }
</style>